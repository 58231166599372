import { accountQueryKeys } from 'containers/account/queries/queryKeys';
import { API } from 'utilities/api/account';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
export const paymentMethods = createNXQuery(() => ({
    queryKey: accountQueryKeys.user.paymentMethods(),
    queryFn: API.user.payment_methods.GET
}));
