import { createRoute } from '@tanstack/react-router';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { VIPSitesVMAXRoute } from 'containers/vmax/routes/vmax';

/**********************************************************************************************************
 *   ROUTE START
 ******************************s****************************************************************************/
export const VIPSitesVMAXThemeRoute = createRoute({
    getParentRoute: () => VIPSitesVMAXRoute,
    path: 'theme'
}).lazy(lazyFN(() => import('./index.lazy'), 'LazyVIPSitesVMAXThemeRoute'));
