import classNames from 'classnames';
import { DevTools } from 'components/DevTools';
import StaffTag from 'components/Staff';
import { staffMenuDevPanelRefStore } from 'components/StaffMenu/consts';
import { StaffMenuContext } from 'components/StaffMenu/context';
import { DeveloperItem, StaffItem } from 'components/StaffMenu/filter';
import { SuperUser } from 'components/StaffMenu/SuperUser';
import { DeveloperTheme } from 'components/StaffMenu/Theme';
import TestRoutes from 'components/TestRoutes';
import { Flex } from 'components/Utils/Flex';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { UserPreferences } from 'utilities/UserPreferences';
import './_StaffMenu.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const StaffMenu = () => {
    /***** STATE *****/
    const [activeMenuItem, setActiveMenuItem] = useState<string>('');
    const [isStaffMenuOpen, setIsStaffMenuOpen] = UserPreferences.useLocalStorage('IS_STAFF_MENU_OPEN', true);
    const isOpen = Boolean(isStaffMenuOpen);

    /***** HOOKS *****/
    const isVPN = useIsVPN();

    /***** FUNCTIONS *****/
    const closeMenu = () => {
        setIsStaffMenuOpen(false);
        setActiveMenuItem('');
    };

    const openMenu = () => {
        setIsStaffMenuOpen(true);
    };

    function handleDevPanelRef(element: HTMLDivElement | null) {
        if (staffMenuDevPanelRefStore.state !== element) {
            staffMenuDevPanelRefStore.setState(() => element);
        }
    }

    /***** RENDER HELPERS *****/
    const className = classNames('StaffMenu', {
        'StaffMenu--open': isStaffMenuOpen,
    });

    /***** RENDER *****/
    if (isVPN || import.meta.env.DEV) {
        return createPortal(
            <StaffMenuContext.Provider value={{ isOpen, active: activeMenuItem, setActive: setActiveMenuItem }}>
                <Flex direction="column" className={className} gap={2}>
                    <StaffItem component={StaffTag} />
                    <StaffItem component={SuperUser} />
                    <StaffItem component={TestRoutes} />

                    <DeveloperItem component={DeveloperTheme} />

                    <div className="StaffMenu__displayToggle">
                        <DevTools.DisplayToggle isOpen={isOpen} handleOpen={openMenu} handleClose={closeMenu} />
                    </div>
                </Flex>
                <div className="StaffMenu__devPanels" ref={handleDevPanelRef} />
            </StaffMenuContext.Provider>,
            document.body
        );
    }
    return null;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
