import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { accountQueryKeys } from 'containers/account/queries/queryKeys';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/account';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const createQueryKey = () => accountQueryKeys.serviceGroups.list();

function createQueryOptions() {
    return infiniteQueryOptions({
        queryKey: createQueryKey(),
        queryFn: ({ pageParam = 1 }) => API.account.GET.serviceGroups.list({ pageParam }),
        select: (data) => data,
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page < lastPage.meta.last_page) {
                return lastPage.meta.current_page + 1; // Fetch the next page
            }
            return undefined; // No more pages
        },
    });
}

function _invalidateQuery() {
    return queryClient.invalidateQueries({ queryKey: createQueryKey() });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useInfiniteQuery() {
    return useInfiniteQuery(createQueryOptions());
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const serviceGroupsList = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery,
    invalidateQuery: _invalidateQuery,
    createQueryKey,
    createQueryOptions,
});
