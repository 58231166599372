/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RouteConfig } from 'router/types';

/**********************************************************************************************************
 *   CONFIG START
 **********************************************************************************************************/
export const routes = {
    /**
     * Special Routes (Must always be enabled)
     */
    '__root__': true,

    '/': true, // redirect handled in component
    '/forgot': {
        active: false,
        redirect: '/_login/login'
    },
    '/reset-password': {
        active: false,
        redirect: '/_login/login'
    },

    // Any authentication level
    '/approve-user/$token': true,
    '/move-service/$moveToken': true,
    '/staff-login/$staffLoginToken': true,
    '/activate': true,

    // Login area routes
    '/_login': true,
    '/_login/login': true,
    '/_login/reset-password/$token': true,

    // Guest Authentication
    '/_guest': true,

    // User Authentication
    '/dashboard': true,

    '/katana/authorize': true,
    '/katana/authorize/instagram': true,
    '/katana/authorize/instagram_business': true,

    '/account': true,
    '/account/general': {
        active: true,
        hashes: {
            'overview': true,
            'personal-details': true,
            'referral': true,
            'close': true,
            'contacts': true,
            'additional-users': true,
            'org-accounts': false,
            'account-access': true,
            'lleyton-only-fans': false
        }
    },
    '/account/security': {
        active: true,
        hashes: {
            'password': true,
            'information': true,
            'two-factor': true,
            'login-history': true
        }
    },
    '/account/emails': {
        active: true,
        hashes: {
            subscriptions: true,
            view: true
        }
    },

    '/my-services': true,

    '/my-services/service-groups': false,

    '/my-services/service-group/$id': false,

    '/my-services/domains': true,
    '/my-services/domains/bulk': true,
    '/my-services/domains/bulk/$section': true,
    '/my-services/domains/$domainId': true,
    '/my-services/domains/$domainId/general': {
        active: true,
        hashes: {
            'add-hosting': true,
            'add-vip-site': true,
            'contacts': true,
            'eligibility-information': true,
            'overview': true,
            'renew': true,
            'registrant-email': true
        }
    },

    '/my-services/domains/$domainId/manage': {
        active: true,
        hashes: {
            'dns': true,
            'child-nameservers': true,
            'dnssec': true
        }
    },

    '/my-services/domains/$domainId/security': {
        active: true,
        hashes: {
            'transfer': true,
            'epp': true,
            'id-protection': true
        }
    },

    '/my-services/domains/$domainId/admin': {
        active: true,
        hashes: {
            move: true,
            cor: true,
            delete: true
        }
    },

    '/my-services/domains/legacy/$legacyDomainId': true,
    '/my-services/domains/legacy/$legacyDomainId/general': {
        active: true,
        hashes: {
            migrate: true,
            overview: true,
            renew: true,
            contacts: true,
            nameservers: true
        }
    },
    '/my-services/domains/legacy/$legacyDomainId/security': {
        active: true,
        hashes: {
            transfer: true,
            epp: true
        }
    },

    '/my-services/hosting': true,
    '/my-services/hosting/$serviceId': true,
    '/my-services/hosting/$serviceId/account': {
        active: true,
        hashes: {
            'overview': true,
            'billing': true,
            'custom-addons': true,
            'custom-hosting': true,
            'details': true,
            'primary-domain': true,
            'purchase': true,
            'upgrade': true,
            'renew': true
        }
    },
    '/my-services/hosting/$serviceId/config': {
        active: true,
        hashes: {
            'ipv6': true,
            'sql': true,
            'ssh': true,
            'temp-url': true
        }
    },
    '/my-services/hosting/$serviceId/security': {
        active: true,
        hashes: {
            autossl: true,
            firewall: true,
            modsec: true,
            permissions: true,
            monarx: true
        }
    },
    '/my-services/hosting/$serviceId/admin': {
        active: true,
        hashes: {
            cancel: true,
            move: true
        }
    },
    '/my-services/hosting/$serviceId/reseller': {
        active: true,
        hashes: {
            subaccounts: true
        }
    },
    '/my-services/hosting/$serviceId/subaccount/$subaccountId': true,
    '/my-services/hosting/$serviceId/subaccount/$subaccountId/account': {
        active: true,
        hashes: {
            'details': true,
            'primary-domain': true
        }
    },
    '/my-services/hosting/$serviceId/subaccount/$subaccountId/config': {
        active: true,
        hashes: {
            'ipv6': true,
            'sql': true,
            'ssh': true,
            'temp-url': true
        }
    },
    '/my-services/hosting/$serviceId/subaccount/$subaccountId/security': {
        active: true,
        hashes: {
            autossl: true,
            firewall: true,
            modsec: true,
            permissions: true,
            monarx: true
        }
    },

    '/my-services/vip-sites': true,
    '/my-services/vip-sites/trial/$katanaTrialDomain': true,
    '/my-services/vip-sites/register/$katanaTrialDomain': true,
    '/my-services/vip-sites/external-add-domain-names/$katanaRandomSubdomain': true,
    '/my-services/vip-sites/$katanaServiceId': true,
    '/my-services/vip-sites/$katanaServiceId/overview': {
        active: true,
        hashes: {
            'setup': true,
            'basic-details': true,
            'launch-checklist': true,
            'colours-fonts': true,
            'finalise': true,
            'website-layout': true
        }
    },
    '/my-services/vip-sites/$katanaServiceId/admin': {
        active: true,
        hashes: {
            dns: true,
            reset: true,
            cancel: true
        }
    },
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/page/$pageId': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/page/$pageId/add-section': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/basic-details': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/contact-information': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/colours-fonts': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/navigation-and-socials': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/template-content/$childPage': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/finalise': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/register-domain': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/setup/launch': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/launch': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/finalise': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/basic-details': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/contact-information': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/colours-fonts': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/navigation-and-socials': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/add-section': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/add-page': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/template-content': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/template-content/$childPage': true,
    '/my-services/vip-sites/$katanaServiceId/_content-editor-lightbox/editor/fix-dns': true,

    '/my-services/email-hosting': true,
    '/my-services/email-hosting/$groupId': true,
    '/my-services/email-hosting/$groupId/general': {
        active: true,
        hashes: {
            'mailboxes': true,
            'add-mailbox': true,
            'details': true
        }
    },
    '/my-services/email-hosting/$groupId/tools': {
        active: true,
        hashes: {
            'aliases': true,
            'spam-filtering-log': true
        }
    },
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId': true,
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId/general': {
        active: true,
        hashes: {
            'overview': true,
            'disk-usage': true,
            'upgrade': true,
            'billing': true,
            'details': true
        }
    },
    '/my-services/email-hosting/$groupId/mailbox/$mailboxId/admin': {
        active: true,
        hashes: {
            activesync: true,
            delete: true
        }
    },

    '/my-services/google': true,
    '/my-services/google/$workspaceId': true,
    '/my-services/google/$workspaceId/manage': {
        active: true,
        hashes: {
            'status': true,
            'overview': true,
            'upgrade': true,
            'billing': true,
            'manage-seats': true,
            'user-accounts': true
        }
    },
    '/my-services/google/$workspaceId/mailbox/$mailboxId/manage': {
        active: true,
        hashes: {
            'user-details': true,
            'delete': true
        }
    },
    '/my-services/google/$workspaceId/admin': {
        active: true,
        hashes: {
            'account-details': true,
            'delete-account': true
        }
    },
    '/my-services/google/$workspaceId/mailbox/$mailboxId': true,
    '/my-services/google/$workspaceId/setup': true,

    '/my-services/microsoft-365': true,
    '/my-services/microsoft-365/$microsoftId': true,
    '/my-services/microsoft-365/$microsoftId/admin': {
        active: true,
        hashes: {
            'delete-subscriptions': true
        }
    },
    '/my-services/microsoft-365/$microsoftId/manage': {
        active: true,
        hashes: {
            'overview': true,
            'upgrade': true,
            'manage-seats': true
        }
    },

    '/my-services/vps': true,
    '/my-services/vps/$vpsId': true,
    '/my-services/vps/$vpsId/account': {
        active: true,
        hashes: {
            'overview': true,
            'renew': true,
            'upgrade-plan': true,
            'resources': true,
            'server-jobs': true,
            'whm-link': true
        }
    },
    '/my-services/vps/$vpsId/addons': {
        active: true,
        hashes: {
            'resource-addons': true,
            'software-licenses': true
        }
    },
    '/my-services/vps/$vpsId/admin': {
        active: true,
        hashes: {
            'cancel-service': true,
            'format-vps': true
        }
    },
    '/my-services/vps/$vpsId/manage': {
        active: true,
        hashes: {
            'power-management': true,
            'vnc-access': true,
            'vnc-login': true,
            'reverse-dns': true
        }
    },

    '/my-services/ssl': true,
    '/my-services/ssl/$sslId': true,
    '/my-services/ssl/$sslId/manage': {
        active: true,
        hashes: {
            details: true,
            install: true,
            overview: true
        }
    },
    '/my-services/ssl/$sslId/configure': {
        active: true,
        hashes: {
            configuration: true,
            review: true
        }
    },
    '/my-services/ssl/$sslId/validate': {
        active: true,
        hashes: {
            further: true,
            validation: true
        }
    },

    '/vip-rewards': true,

    '/billing': true,
    '/billing/payment-methods': true,
    '/billing/invoices': true,
    '/billing/invoice': true,
    '/billing/invoice/$invoiceId': true,
    '/billing/invoice/$invoiceId/cancel': true,
    '/billing/invoice/$invoiceId/pay': true,
    '/billing/orders': false,
    '/billing/order': false,
    '/billing/order/$orderId': false,
    '/billing/split-invoice/$invoiceId': true,
    '/billing/ventraip-wallet': true,
    '/billing/invoices-merge': true,
    '/billing/sla': true,
    '/billing/services-alignment': true,

    '/support': true,
    '/support/support-centre': true,
    '/support/support-centre/$supportArticle': true,
    '/support/tickets': true,
    '/support/tickets/$section': true,
    '/support/tickets/$section/$param4': true,
    '/support/tickets/$section/$param4/$param5': true,
    '/support/migration-request': true,
    '/support/feedback': true,
    '/support/eTickets': true,

    '/contact-support': false,

    /** Some support center articles have links to other support center articles within them. We need to redirect these from `{baseUrl}/support-centre/thing -> {baseUrl}/support/support-centre/thing. Otherwise they will 404, because in VIPcontrol, all support centre routes are prefixed with /support, whereas on the website they are not. */
    '/support-centre/$supportArticle': {
        active: false,
        redirect: ({ supportArticle }) => `/support/support-centre/${supportArticle}`
    },

    '/synergy-reseller-migration': true,
    '/synergy-reseller-migration/more-info': true,
    '/synergy-reseller-migration/select-services': true,
    '/synergy-reseller-migration/confirm-migration': true,
    '/synergy-reseller-migration/create-account': true,
    '/synergy-reseller-migration/success': true,

    /** Legacy redirects, should remove these routes after 9/10/2025 */
    '/my-services/domains/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/domains/${id}/${section}`
    },
    '/my-services/hosting/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/hosting/${id}/${section}`
    },
    '/my-services/hosting/$section/$module/$id/subaccount/$subaccountId': {
        active: false,
        redirect: ({ id, section, subaccountId }) => `/my-services/hosting/subaccount/${subaccountId}/${id}/${section}`
    },
    '/my-services/email-hosting/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/email-hosting/${id}/${section}`
    },
    '/my-services/email-hosting/$section/$module/$id/mailbox/$mailboxId': {
        active: false,
        redirect: ({ id, section, mailboxId }) => `/my-services/email-hosting/mailbox/${mailboxId}/${id}/${section}`
    },
    '/my-services/google/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/google/${id}/${section}`
    },
    '/my-services/microsoft-365/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/microsoft-365/${id}/${section}`
    },
    '/my-services/vps/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/vps/${id}/${section}`
    },
    '/my-services/ssl/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/ssl/${id}/${section}`
    },
    '/my-services/vip-sites/$section/$module/$id': {
        active: false,
        redirect: ({ id, section }) => `/my-services/vip-sites/${id}/${section}`
    },
    '/home/account/$section/$module': {
        active: false,
        redirect: ({ section }) => `/account/${section}`
    }
} as const satisfies RouteConfig;
