import type { AlternateBrandPrimitiveTokens } from 'config/tokens/types';

/**********************************************************************************************************
 *   TOKEN START
 **********************************************************************************************************/
/**
 * @warning DO NOT USE INSIDE CCP
 */
export const _intaservePrimitive = {
    'P_color_primary': '#1256fa',
    'P_color_primary_hover': '#104bd5',
    'P_color_secondary': '#e0dfe6',
    'P_color_secondary_hover': '#e0dfe6',
    'P_color_tertiary': '#f1f1fb',
    'P_color_tertiary_hover': '#e3e3f7',
    'P_color_confirm': '#00cc66',
    'P_color_confirm_hover': '#00cc66',
    'P_color_info': '#1256fa',
    'P_color_info_hover': '#1256fa',
    'P_color_notice': '#f76d36',
    'P_color_notice_hover': '#f76d36',
    'P_color_warn': '#f73660',
    'P_color_warn_hover': '#f73660',
    'P_color_white': '#FFFFFF',
    'P_color_black': '#000000',
    'P_color_grey': '#919ba7',
    'P_border-radius_default': '10px',
    // Above here are "compulsory", below are additional

    'P_color_lilac_600': '#d8d8eb',
    'P_color_lilac_700': '#837f9b',
    'P_color_lilac_800': '#605d7f',
    'P_color_lilac_900': '#4d4f6e',

    'P_color_navy_400': '#1f236c',
    'P_color_navy_500': '#12143e',

    'P_color_grey_50': '#f5f7f8',
    'P_color_grey_80': '#eaeaea',
    'P_color_grey_100': '#f5f6f7',
    'P_color_grey_125': '#e6e6e6',
    'P_color_grey_150': '#e6e6f1',
    'P_color_grey_275': '#d7dfe3',
    'P_color_grey_300': '#bebebe',
    'P_color_grey_350': '#aeafbd',
    'P_color_grey_400': '#abb2ba',
    'P_color_grey_450': '#dcdcdc',
    'P_color_grey_500': '#d8d7e0',
    'P_color_grey_525': '#c8c8c8',
    'P_color_grey_550': '#d0d0df',
    'P_color_grey_600': '#a0a1b6',
    'P_color_grey_650': '#88899e',

    'P_color_grey_800': '#686868',
    'P_color_transparent': 'transparent',
    'P_color_skyBlue_350': '#e5f1ff',
    'P_color_blue_100': '#e3ecff',
    'P_color_blue_200': '#adc1f0',
    'P_color_blue_250': '#88aafc',
    'P_color_blue_300': '#779df5',
    'P_color_orange_100': '#fdf1da',
    'P_color_orange_200': '#ffe090',
    'P_color_orange_300': '#ffa500',
    'P_color_orange_400': '#fd9727',
    'P_color_orange_500': '#fc7052',
    'P_color_pink_400': '#e72564',
    'P_color_purple_400': '#9a30ae',
    'P_color_purple_800': '#25264d',
    'P_color_black_half_opacity': '#34363680',
    'P_color_aqua_200': '#9afbe6',
    'P_color_aqua_400': '#159587',
    'P_color_green_400': '#8cc252',
    'P_color_violet_400': '#663fb4',

    'P_border-width_1': '1px',
    'P_border-width_3': '3px',

    'P_border-radius_2': '2px',
    'P_border-radius_4': '4px',
    'P_border-radius_5': '5px',
    'P_border-radius_6': '6px',
    'P_border-radius_7': '7px',
    'P_border-radius_8': '8px',
    'P_border-radius_10': '10px',
    'P_border-radius_12': '12px',
    'P_border-radius_15': '15px',
    'P_border-radius_50': '50px',

    'P_margin_20': '20px',

    'P_box-shadow_none': 'none',

    'P_padding_30': '30px',

    /**
     * VMax Primitive Tokens
     */
    'P_color_primary_vmax': '#cc0099',
    'P_color_primary-darker_vmax': '#b8008a',
    'P_color_primary-darkest_vmax': '#a5007c',
    'P_color_primary-fade_vmax': '#ffccf2',
    'P_color_primary-light_vmax': '#ffe0f7',
    'P_color_primary-lightest_vmax': '#fff3fc',

    /**
     * VMax Greys Colours
     */
    'P_color_containers_vmax': '#f5f5f5',
    'P_color_dark_containers_vmax': '#e5e5e5',
    'P_color_lines_vmax': '#dcdcdc',
    'P_color_darker-lines_vmax': '#b4b4b4',

    /**
     * VMax Text Colours
     */
    'P_color_ebony_vmax': '#09091f',
    'P_color_ebony_light_vmax': '#585867',
    'P_color_ebony_lighter_vmax': '#7a7a87',
    'P_color_ebony_lightest_vmax': '#9e9ea8',

    /**
     * VMax Colours
     */
    'P_color_green_vmax': '#008c99',
    'P_color_red_vmax': '#e63946',
    'P_color_visual-editor_vmax': '#00ffcc',
    'P_color_links_vmax': '#0846ff',
} satisfies AlternateBrandPrimitiveTokens;
