import { useNavigate } from '@tanstack/react-router';
import { default as upgradeBackgroundImage } from 'assets/images/ssl/ssl_banner_background.jpeg';
import upgradeImage from 'assets/images/ssl/ssl_banner_icon.svg';
import intaserveUpgradeImage from 'assets/images/ssl/ssl_intaserve_banner_icon.svg';
import classNames from 'classnames';
import { SolidButton } from 'components/Buttons/SolidButton';
import { getInvoiceActionUrl } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox/methods';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { getSslList } from 'containers/services/action';
import 'containers/services/forms/ssl/_ssl.scss';
import { ProductCard } from 'containers/services/forms/ssl/productCard';
import type { Product } from 'containers/services/forms/ssl/types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Payment = {
    id: number;
    module_id?: number;
    name: string;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SSLPurchase = () => {
    /***** STATE *****/
    const [showProductsDropdown, setShowProductsDropdown] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<Array<Product>>([]);

    /***** HOOKS *****/
    const { application, activeBrand } = useBrandStore();
    const isMobileOrTablet = useAppViewport(['xs', 'sm', 'md']);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /***** QUERIES *****/
    const { data: ssl_products_data, isLoading: isSslProductsDataLoading } = NXQuery.ssl.sslProduct.useQuery();
    const { mutate: createCart, isPending: isCreateCartPending } = NXQuery.orderingForm.cart.createCartInternal.useMutation();
    const { data: payment_methods_data } = NXQuery.orderingForm.cart.getPaymentMethods.useQuery();
    const { mutate: createOrder, isPending: isCreateOrderPending } = NXQuery.orderingForm.cart.cartId.createOrder.useMutation();

    const isOrderProcessing = isCreateCartPending || isCreateOrderPending;

    /***** FUNCTIONS *****/
    const getTotalPrice = () => {
        return selectedProducts
            .reduce((total, product) => {
                return total + Number(product.price);
            }, 0)
            .toFixed(2);
    };

    const getPaymentMethodId = (methodName: string) => payment_methods_data?.find((payment: Payment) => payment?.name === methodName)?.id;
    const applyCredit = activeBrand === 'intaserve';

    const handleConfirmOrder = (e: React.SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        // Sort the products so they look nicer on the invoice
        selectedProducts.sort((a, b) => a.id - b.id);
        // Create the cart with the products
        createCart(selectedProducts, {
            // If success create the order
            onSuccess: ({ data }) => {
                if (data?.token) {
                    const paymentId = activeBrand === 'intaserve' ? getPaymentMethodId('') : getPaymentMethodId('EFT') || getPaymentMethodId('BPAY');

                    createOrder(
                        // If success show payment lightbox, unless Intaserve, then show toast
                        { paymentMethodId: paymentId, tokenId: data.token as string, applyCredit },
                        {
                            onSuccess: (data) => {
                                UserPreferences.removeItem('CART_TOKEN');
                                getSslList()(dispatch);
                                window.dispatchEvent(new Event('cartTokenChanges'));

                                if (activeBrand === 'intaserve') {
                                    pushNotification({ status: 200, details: 'Certificate(s) ordered successfully' });
                                } else {
                                    navigate({ to: getInvoiceActionUrl('pay', data.data.invoice_id) });
                                }
                                setSelectedProducts([]);
                                setShowProductsDropdown(false);
                            },
                            onError: () => {},
                        }
                    );
                } else {
                    console.error('Cart token not found.');
                }
            },
        });
    };

    /***** RENDER HELPERS *****/
    const renderProducts = () => {
        if (ssl_products_data?.data && ssl_products_data?.data.length > 0) {
            return ssl_products_data?.data.map((product: any) => {
                return (
                    <ProductCard
                        key={product.attributes.name}
                        product={product}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        isOrderProcessing={isOrderProcessing}
                    />
                );
            });
        }
        return;
    };

    /***** RENDER *****/
    return (
        <>
            <div>
                <ServiceOverviewBanner
                    type={activeBrand === 'intaserve' ? 'manual' : 'image'}
                    src={activeBrand !== 'intaserve' ? upgradeBackgroundImage : ''}
                    className={classNames({
                        sslProducts__intaserveBannerBackground: activeBrand === 'intaserve',
                    })}
                >
                    <ServiceOverviewBanner.ContentWrapper>
                        <ServiceOverviewBanner.Heading>Buy SSL Certificates</ServiceOverviewBanner.Heading>
                        <ServiceOverviewBanner.Description>
                            Secure your website by purchasing and installing an SSL certificate directly within {application}.
                        </ServiceOverviewBanner.Description>
                        <ServiceOverviewBanner.Button type="onClick" onClick={() => setShowProductsDropdown(!showProductsDropdown)}>
                            View Products
                        </ServiceOverviewBanner.Button>
                    </ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Image src={activeBrand === 'intaserve' ? intaserveUpgradeImage : upgradeImage} alt="Purchase Hosting" />
                </ServiceOverviewBanner>
            </div>

            <NXBox className="sslProducts" isLoading={isSslProductsDataLoading && showProductsDropdown}>
                <NXBox.Dropdown isOpen={showProductsDropdown}>
                    <Padding left={isMobileOrTablet ? 5 : 17} right={6} top={7}>
                        <Flex align="start" justify="between">
                            <Text size--l semiBold>
                                Select your SSL Certificate
                            </Text>
                            <NXBox.Dropdown.CloseButton onClose={() => setShowProductsDropdown(false)} />
                        </Flex>
                    </Padding>
                    <Padding x={isMobileOrTablet ? 5 : 17} y={8} top={5}>
                        <Flex direction="column">{renderProducts()}</Flex>
                        <Padding top={10} bottom={3}>
                            <Flex justify="between" align="center" className="sslProducts__totalAmount">
                                <Text size--custom={13} semiBold color="S_color_text_secondary_base">
                                    Total Amount Due
                                </Text>
                                <Text size--l semiBold color="C_SslForm__TotalAmount_color_text">
                                    ${getTotalPrice()} AUD
                                </Text>
                            </Flex>
                        </Padding>
                        <div className="sslProducts__verticalDivider" />
                        <div>
                            <SolidButton type="onClick" onClick={(e) => handleConfirmOrder(e)} disabled={isOrderProcessing}>
                                {isOrderProcessing ? <RequestLoader /> : 'Confirm Order'}
                            </SolidButton>
                        </div>
                    </Padding>
                </NXBox.Dropdown>
            </NXBox>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
