import PayIdImage from 'assets/images/payid.png';
import classNames from 'classnames';
import { Radio } from 'components/Form/Radio';
import Grid from 'components/Grid';
import type { SelectedPaymentMethodProps } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/types';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { NXQuery } from 'utilities/query';
import { PAYMENT_METHODS } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PayIdPaymentMethod: React.FC<SelectedPaymentMethodProps> = ({ selectedPaymentMethod, setSelectedPaymentMethod }) => {
    /***** QUERIES *****/
    const { data: account_number } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes.account_number);
    const { data: payId_payment_data } = NXQuery.account.paymentMethods.useSelectSuspenseQuery(void 0, ({ data }) =>
        data.find((paymentMethod) => paymentMethod?.name === 'PayID')
    );

    /***** RENDER HELPERS *****/
    const isPayIdSelected = selectedPaymentMethod === PAYMENT_METHODS.PAYID;

    /***** RENDER *****/
    return (
        <div key="payId" className={classNames('method', { 'method--selected': isPayIdSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isPayIdSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.PAYID)}
            >
                <div className="method__top--title">
                    <div className="label">PayID</div>
                </div>
                <div className="method__top--img">
                    <img className="cc_icon payid" src={PayIdImage} alt="eft" />
                </div>
            </Radio.Circle.Button>
            {isPayIdSelected && (
                <Padding top={5} x={4} bottom={6}>
                    <Flex direction="column" gap={1}>
                        <Grid columns="45% 55%" gap={0}>
                            <Text size--xss uppercase bold secondary>
                                PayID
                            </Text>
                            <Text>{payId_payment_data?.module_details?.identifier}</Text>
                        </Grid>
                        <Grid columns="45% 55%" gap={0}>
                            <Text size--xss uppercase bold secondary>
                                PayID Name
                            </Text>
                            <Text>{payId_payment_data?.module_details?.name}</Text>
                        </Grid>
                        <Grid columns="45% 55%" gap={0}>
                            <Text size--xss uppercase bold secondary>
                                Reference #
                            </Text>
                            <Text>{account_number}</Text>
                        </Grid>
                        <Text size--s className="payId__note">
                            Please note we recommend allowing 1-2 business days for payments to process (this may be further delayed by weekends and
                            public holidays).
                        </Text>
                    </Flex>
                </Padding>
            )}
        </div>
    );
};
