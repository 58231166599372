import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'utilities/api/domain';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { DomainData } from 'containers/orderingForm/types';

const defaultOptions = {
    page: 1,
    limit: 8
};

/***** FUNCTIONS *****/
function createQueryKey(domainData: DomainData, options = defaultOptions, pageParam: number) {
    return createBaseQueryKey(['domain', domainData, { ...options, page: pageParam }]);
}

function createQueryOptions(domainData: DomainData, options = defaultOptions) {
    return infiniteQueryOptions({
        queryKey: createQueryKey(domainData, options, 1),
        queryFn: ({ pageParam = options.page }) => API.domain.POST.suggestion(domainData, { ...options, page: pageParam }),
        enabled: Boolean(domainData.domain),
        select: (data) => data,
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            if (lastPage.meta.current_page < lastPage.meta.last_page) {
                return lastPage.meta.current_page + 1;
            }
            return undefined;
        }
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useInfiniteQuery(domainSuggestionData: DomainData, options = defaultOptions) {
    return useInfiniteQuery(createQueryOptions(domainSuggestionData, options));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const suggestion = Object.freeze({
    useInfiniteQuery: _useInfiniteQuery,
    createQueryKey,
    createQueryOptions
});
