/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createRoute } from '@tanstack/react-router';
import PageNotFound from 'components/Errors/PageNotFound';

/**********************************************************************************************************
 *   ROUTE IMPORTS
 **********************************************************************************************************/
import { AppRoute } from 'App';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ServiceGroupsRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/my-services/service-groups',
    notFoundComponent: PageNotFound,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    }
}).lazy(lazyFN(() => import('./serviceGroups.lazy'), 'LazyServiceGroupsRoute'));
