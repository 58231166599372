/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import SelectDropdown from 'components/Dropdowns/SelectDropdown';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { renderForBrands } from 'config/brandRenderer/helper';
import { serviceNav } from 'config/config';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { useSelector } from 'react-redux';
import { isRouteActive } from 'router/utils/isRouteActive';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { useConditionalServiceNavKeys } from 'utilities/hooks/useConditionalServiceNavKeys';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
/**
 * @typedef {<T extends boolean>(isMobile: T) => (item: string) => T extends false ? React.ReactNode : { label: string; onClick: () => void }} TGetOptions
 */

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<{
 *   view: NXUtils.ValueOf<typeof serviceNav>['key']
 *   setView: (view: NXUtils.ValueOf<typeof serviceNav>['key']) => void
 * }>}
 */
export const ExpressServiceManagerTabs = ({ view, setView: _setView }) => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    const isMobile = useAppViewport(['xs', 'sm']);
    const vps_list_meta = useSelector((state) => state.services.vps_list_meta);
    const doesUserHaveAVps = vps_list_meta?.total > 0;

    /***** FUNCTIONS *****/
    /**
     * @param {NXUtils.ValueOf<typeof serviceNav>['key']} view
     */
    const setView = (view) => {
        switch (view) {
            case serviceNav['cPanel Hosting'].key:
            case serviceNav['Email Hosting'].key:
            case serviceNav['Google Workspace'].key:
            case serviceNav['VPS'].key:
            case serviceNav['SSL Certificates'].key:
            case serviceNav['Microsoft 365'].key:
            case serviceNav['VIPsites'].key:
            case serviceNav['Domain Names'].key:
                return _setView(view);
            default:
                return _setView(serviceNav['Domain Names'].key);
        }
    };

    /***** RENDER HELPERS *****/
    const serviceNavConfigKeys = useConditionalServiceNavKeys();

    /**
     * @type {TGetOptions}
     */
    const getOptions = (isMobile) => {
        // @ts-ignore
        return function options(item) {
            const itemLabel = renderForBrands(['intaserve']) && item === 'cPanel Hosting' ? 'Hosting' : item;

            const className = classNames('expressServiceManager__tabs--option', {
                active: view === serviceNav[item].key
            });

            if (isMobile) {
                return {
                    label: itemLabel,
                    onClick: () => setView(serviceNav[item].key)
                };
            }

            return (
                <button type="button" key={serviceNav[item].key} className={className} onClick={() => setView(serviceNav[item].key)}>
                    {itemLabel}
                </button>
            );
        };
    };

    const optionsForActiveBrand = serviceNavConfigKeys.filter((option) => {
        switch (option) {
            case 'VIPsites':
                return isRouteActive({ path: '/my-services/vip-sites' });
            case 'Email Hosting':
                return isRouteActive({ path: '/my-services/email-hosting' });
            case 'Google Workspace':
                return isRouteActive({ path: '/my-services/google' });
            case 'Microsoft 365':
                return isRouteActive({ path: '/my-services/microsoft-365' });
            case 'VPS':
                return isRouteActive({ path: '/my-services/vps' }) && (activeBrand !== 'intaserve' || doesUserHaveAVps);
            case 'SSL Certificates':
                return isRouteActive({ path: '/my-services/ssl' });
            case 'Service Groups':
                return isRouteActive({ path: '/my-services/service-groups' });
            default:
                return true;
        }
    });

    /***** RENDER *****/
    return isMobile ? (
        <SelectDropdown
            noSelectionLabel="Domain Management"
            className="expressServiceManager__dropdown"
            options={optionsForActiveBrand.map(getOptions(true))}
        />
    ) : (
        <div className={classNames('expressServiceManager__tabs', { 'expressServiceManager__tabs--small': optionsForActiveBrand.length < 4 })}>
            {optionsForActiveBrand.map(getOptions(false))}
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
