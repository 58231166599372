import { createRoute } from '@tanstack/react-router';
import { fallback, zodValidator } from '@tanstack/zod-adapter';
import { AppRoute } from 'App';
import PageNotFound from 'components/Errors/PageNotFound';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';
import { z } from 'zod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const defaultSearch = {
    selected_service: 'domain-names',
} as const;

/**********************************************************************************************************
 *   SCHEMA
 **********************************************************************************************************/
export const myServicesHostingSchema = z.object({
    selected_service: fallback(z.union([z.literal('domain-names'), z.literal('ssl-certificates')]), defaultSearch.selected_service).default(
        defaultSearch.selected_service
    ),
});

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ServiceGroupRoute = createRoute({
    getParentRoute: () => AppRoute,
    path: '/my-services/service-group/$id',
    notFoundComponent: PageNotFound,
    beforeLoad(opts) {
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
    },
    validateSearch: zodValidator(myServicesHostingSchema),
}).lazy(lazyFN(() => import('./serviceGroup.lazy'), 'LazyServiceGroupRoute'));
