import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   QUERY KEY TREE START
 **********************************************************************************************************/
export const accountQueryKeys = Object.assign(() => createBaseQueryKey(['account']), {
    checkSubaccountExists: (token: string) => [...accountQueryKeys(), 'checkSubaccountExists', token as '$token'] as const,
    user: Object.assign(() => [...accountQueryKeys(), 'user'] as const, {
        auth: Object.assign(() => [...accountQueryKeys.user(), 'auth'] as const, {
            code: () => [...accountQueryKeys.user.auth(), 'code'] as const,
        }),
        paymentMethods: Object.assign(() => [...accountQueryKeys.user(), 'paymentMethods'] as const),
    }),
    serviceGroups: Object.assign(() => [...accountQueryKeys(), 'serviceGroups'] as const, {
        list: () => [...accountQueryKeys.serviceGroups(), 'list'] as const,
    }),
});

export const accountMutationKeys = Object.assign(() => createBaseQueryKey(['account']), {
    approveSubaccount: () => [...accountMutationKeys(), 'approveSubaccount'] as const,
    serviceGroups: Object.assign(() => [...accountMutationKeys(), 'serviceGroups'] as const, {
        add: () => [...accountMutationKeys.serviceGroups(), 'add'] as const,
        delete: () => [...accountMutationKeys.serviceGroups(), 'delete'] as const,
        edit: () => [...accountMutationKeys.serviceGroups(), 'edit'] as const,
    }),
});
